import type { FromModel, ModelDefinition } from "@/__main__/data-model.mjs";
import createModel from "@/__main__/data-model.mjs";

const model = [
  {
    gameId: String,
    ratingAfter: Number,
    ratingBefore: Number,
    tierAfter: Number,
    tierBefore: Number,
  },
] satisfies ModelDefinition;

const ValorantRankedRatingsForActModel = createModel({
  data: { riotAccount: { valorantProfile: { matchStatsFromClient: model } } },
}).transform(
  model,
  (data) => data.data.riotAccount.valorantProfile.matchStatsFromClient,
);

export type ValorantRankedRatingsForAct = FromModel<typeof model>;

export default ValorantRankedRatingsForActModel;
