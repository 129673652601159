import getData from "@/__main__/get-data.mjs";
import ValorantLatestPlayerStats from "@/data-models/valorant-latest-player-stats.mjs";
import ValorantPlayerActSummariesModel from "@/data-models/valorant-player-act-summaries.mjs";
import ValorantPlayerAgentStats from "@/data-models/valorant-player-agent-stats.mjs";
import ValorantPlayerMapStats from "@/data-models/valorant-player-map-stats.mjs";
import ValorantPlayerMatchesModel from "@/data-models/valorant-player-matches.mjs";
import ValorantPlayerStats from "@/data-models/valorant-player-stats.mjs";
import ValorantPlayerWeaponStats from "@/data-models/valorant-player-weapon-stats.mjs";
import ValorantRankedRatingsForAct from "@/data-models/valorant-ranked-ratings-for-act.mjs";
import * as API from "@/game-val/api.mjs";
import {
  DEFAULT_MATCHES_LIMIT,
  EXCLUDED_QUEUES,
} from "@/game-val/constants.mjs";
import fetchConstants from "@/game-val/fetch-constants-data.mjs";
import fetchLiveMatch from "@/game-val/fetch-live-match.mjs";
import fetchMatch from "@/game-val/fetches/match.mjs";
import isValidNameTag from "@/game-val/is-valid-nametag.mjs";
import { isPrivateProfile } from "@/game-val/profile-utils.mjs";
import { shouldDisplayActReviewPromo } from "@/game-val/use-act-review-data.mjs";
import {
  getActiveSeason,
  getActOptions,
  separateNameTag,
} from "@/game-val/utils.mjs";
import { getPlatformPath } from "@/game-val/utils/console.mjs";
import fetchProfile, {
  getAuthorizationHeaders,
} from "@/game-val/valorant-fetch-profile.mjs";
import { devWarn } from "@/util/dev.mjs";

export default async function fetchData(parameters, urlSearchParams, state) {
  const [nameTag] = parameters;
  const { name, tag } = separateNameTag(nameTag);
  if (!nameTag || !name || !tag || !isValidNameTag(nameTag)) return;

  const isConsole = urlSearchParams?.get("console") === "1";
  const platformPath = getPlatformPath(isConsole);

  const promises = [];
  const headers = await getAuthorizationHeaders();
  const shouldFetchIfPathExists =
    state?.shouldFetchIfPathExists ?? !state?.isUpdate;
  let offset = 0;
  if (state?.offset) {
    offset = state?.offset;
  }

  const [content] = await fetchConstants();

  const valorantPlayer = await fetchProfile(name, tag);

  const isPrivate = isPrivateProfile(valorantPlayer);

  if (isPrivate) return;
  if (!valorantPlayer || !valorantPlayer.valorantProfile?.region) {
    devWarn("Mandatory info is missing from profile data", valorantPlayer);
    return;
  }

  const puuid = valorantPlayer.puuid;
  const region = valorantPlayer.valorantProfile?.region;

  const actId =
    urlSearchParams.get("actId") || getActiveSeason(content?.acts)?.id;
  const actOptions = getActOptions(content?.acts);
  const endedActId = actOptions[1]?.key;
  const endedActDate = actOptions[1]?.endedAt;

  const getActSummaries = getData(
    API.getActSummaries({ puuid }),
    ValorantPlayerActSummariesModel,
    ["val", "actSummaries", puuid],
    {
      shouldFetchIfPathExists: false,
      headers,
    },
  );

  const queueKey = urlSearchParams.get("queue") || "overall";

  const paramsForMatchHistories = {
    puuid,
    actUuid: actId,
    offset: offset,
    first: DEFAULT_MATCHES_LIMIT,
    queue: queueKey === "overall" ? null : queueKey,
    console: isConsole,
  };

  const getMatchHistories = getData(
    API.getPlayerMatches(paramsForMatchHistories),
    ValorantPlayerMatchesModel,
    ["val", "playerMatches", puuid, actId, platformPath, queueKey],
    {
      shouldFetchIfPathExists:
        shouldFetchIfPathExists || offset !== state.prevOffset,
      mergeFn: (curValues, newValues) => {
        const filteredNewValues = newValues.filter((newMatch) => {
          return !curValues.find(
            (c) => c.match.gameId === newMatch.match.gameId,
          );
        });
        const allValues = [...curValues, ...filteredNewValues];
        return allValues.sort(
          (a, b) => new Date(a.match.gameStart) - new Date(b.match.gameStart),
        );
      },
      headers,
    },
  ).then((matches) => {
    if (!matches || matches instanceof Error || matches.length === 0) return;
    const uniqQueues = new Set();
    matches.forEach((match) => uniqQueues.add(match.queue));
    const promises = [];
    uniqQueues.forEach((queue) => {
      promises.push(
        getData(
          API.getLatestPlayerStats({
            puuid: puuid,
            region: valorantPlayer.valorantProfile.region,
            queue,
          }),
          ValorantLatestPlayerStats,
          ["val", "latestPlayerStats", puuid, queue],
          { headers },
        ),
      );
    });
    return Promise.all(promises);
  });

  if (queueKey === "overall") {
    const getMatchHistoriesForHeadshotGraph = getData(
      API.getPlayerMatches({
        ...paramsForMatchHistories,
        offset: 0,
        excludeQueues: ["deathmatch"],
      }),
      ValorantPlayerMatchesModel,
      ["val", "playerMatches", puuid, actId, platformPath, "headshots"],
      {
        shouldFetchIfPathExists,
        mergeFn: (curValues, newValues) => {
          return newValues.sort(
            (a, b) => new Date(b.match.gameStart) - new Date(a.match.gameStart),
          );
        },
        headers,
      },
    );
    promises.push(getMatchHistoriesForHeadshotGraph);
  }

  // No console ranked yet, so we'll skip fetching this
  const getRankedRatingsForAct = !isConsole
    ? getData(
        API.getRankedRatingsForAct({
          puuid,
          actUuid: actId,
        }),
        ValorantRankedRatingsForAct,
        ["val", "rankedRatingsForAct", puuid, actId],
        { shouldFetchIfPathExists, headers },
      )
    : [];

  const getPlayerStats = getData(
    API.getPlayerStats({
      puuid,
      actUuid: actId,
      region,
      console: isConsole,
    }),
    ValorantPlayerStats,
    ["val", "playerActStats", puuid, actId, platformPath],
    { shouldFetchIfPathExists, headers },
  );

  const getEndedActPlayerStats = getData(
    API.getPlayerStats({
      puuid,
      actUuid: endedActId,
      region,
      console: isConsole,
    }),
    ValorantPlayerStats,
    ["val", "playerActStats", puuid, endedActId, platformPath],
    { shouldFetchIfPathExists, headers },
  );

  if (shouldDisplayActReviewPromo(endedActDate)) {
    promises.push(getEndedActPlayerStats);
  }

  const getPlayerAgentStats = getData(
    API.getAgentStats({
      puuid,
      actUuid: actId,
      region,
      queue: queueKey === "overall" ? null : queueKey,
      excludeQueues: queueKey === "overall" ? EXCLUDED_QUEUES : [],
      console: isConsole,
    }),
    ValorantPlayerAgentStats,
    ["val", "playerAgentStats", puuid, actId, platformPath, queueKey],
    { shouldFetchIfPathExists, headers },
  );

  const getPlayerWeaponStats = getData(
    API.getWeaponStats({
      puuid,
      actUuid: actId,
      region,
      queue: queueKey === "overall" ? null : queueKey,
      console: isConsole,
    }),
    ValorantPlayerWeaponStats,
    ["val", "playerWeaponStats", puuid, actId, platformPath, queueKey],
    { shouldFetchIfPathExists, headers },
  );

  const getPlayerMapStats = getData(
    API.getMapStats({
      puuid,
      actUuid: actId,
      region,
      queue: queueKey === "overall" ? null : queueKey,
      console: isConsole,
    }),
    ValorantPlayerMapStats,
    ["val", "playerMapStats", puuid, actId, platformPath, queueKey],
    { shouldFetchIfPathExists, headers },
  );

  if (state?.expandedMatchId) {
    promises.push(fetchMatch(state.expandedMatchId, region, actId, isConsole));
  }

  promises.push(
    getActSummaries,
    getMatchHistories,
    getRankedRatingsForAct,
    getPlayerStats,
    getPlayerAgentStats,
    getPlayerWeaponStats,
    getPlayerMapStats,
  );

  const liveMatch = await fetchLiveMatch(valorantPlayer);
  const livePlayerProfiles =
    liveMatch?.players?.map(({ gameName, tagLine }) =>
      fetchProfile(gameName, tagLine),
    ) ?? [];
  promises.push(...livePlayerProfiles);

  return Promise.all(promises);
}
